<template>
  <div class="editor">
    <editor-menu-bar
      v-if="enableContentOptions"
      v-slot="{ commands, isActive }"
      :editor="editor"
    >
      <div class="menubar">
        <button
          class="btn"
          :class="{ 'btn-dark': isActive.link() }"
          @click="showLinkMenu(getMarkAttrs('link'))"
        >
          Link
        </button>
        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-dark': isActive.italic() }"
          @click="commands.italic"
        >
          <font-awesome-icon :icon="italicIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-dark': isActive.bold() }"
          @click="commands.bold"
        >
          <font-awesome-icon :icon="boldIcon" />
        </button>
      </div>
    </editor-menu-bar>
    <div class="row">
      <div
        class="col-md-12"
        :class="{'col-11': !isMultiLine}"
      >
        <editor-content
          class="content-editor editor__content"
          :class="{ 'custom-content-height': height > 0 }"
          :style="computedHeight"
          :editor="editor"
        />
      </div>
      <div
        v-if="!isMultiLine"
        class="px-0 d-md-none col-1 pointer"
      >
        <font-awesome-icon
          :icon="sendIcon"
          class="h3 heading-3 send-icon gf-driver-text"
          @click="postContent"
        />
      </div>
    </div>
    <div class="row py-2">
      <div class="d-none d-md-block col-md-6" />
      <div class="col-md-6 text-right">
        <div
          class="col-6 btn text-secondary btn-cancel-post text-right"
          @click="cancelPost"
        >
          Cancel
        </div>
        <div
          class="d-md-inline-block col-6 gf-driver-button btn gf-driver-button-rounded px-0 btn-post-content"
          :class="{'d-none': !isMultiLine}"
          @click="postContent"
        >
          {{ postButtonText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logger from "../../common/logger";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import { Bold, Link, Italic } from "./TipTapExtensions/GfTipTapExtensions";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faChevronCircleRight,
    faBold,
    faItalic
} from "@fortawesome/free-solid-svg-icons";

export default {
    name: "ContentPost",
    components: {
        EditorContent,
        EditorMenuBar,
        FontAwesomeIcon
    },
    props: {
        enableContentOptions: {
            type: Boolean,
            default: false
        },
        characterLimit: {
            type: Number,
            default: 0
        },
        postButtonText: {
            type: String,
            default: "Comment"
        },
        height: {
            type: Number,
            default: 0
        },
        defaultContent: {
            type: String,
            default: "Thank you"
        }
    },
    data() {
        return {
            editor: null,
            config: {},
            content: null,
            sendIcon: faChevronCircleRight,
            boldIcon: faBold,
            italicIcon: faItalic,
            linkUrl: null,
            linkMenuIsActive: false
        };
    },
    computed: {
        isMultiLine() {
            return this.height > 0;
        },

        computedHeight() {
            return `--customHeight: ${this.height}px`;
        }
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                new Bold(),
                new Link(),
                new Italic()
                //new Strike()
                //new Underline()
            ],
            onUpdate: this.onEditorUpdate,
            content: this.defaultContent
        });

        this.content = {
            html: this.editor.getHTML(),
            json: this.editor.getJSON()
        };
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {
        onEditorUpdate(state) {
            Logger.logInfo(
                "ContentPost.onEditorUpdate() - Original: " + state.getHTML()
            );
            if (
                this.characterLimit > 0 &&
                state.getHTML().length > this.characterLimit
            )
                this.editor.setContent(
                    state.getHTML().substring(0, this.characterLimit)
                );

            Logger.logInfo(
                "ContentPost.onEditorUpdate() - Final: " + state.getHTML()
            );
            this.content = {
                html: state.getHTML(),
                json: state.getJSON()
            };
        },

        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.linkInput.focus();
            });
        },
        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu();
        },
        postContent() {
            Logger.logInfo(this.content);
            this.$emit("submit", this.content);
        },

        cancelPost() {
            this.$emit("cancel");
        }
    }
};
</script>
<style>
.content-editor .ProseMirror {
    border: 1px solid #aaa;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.2em;
    border-radius: 10px;
    min-height: 35px;
}

.send-icon {
    min-height: 35px;
}

.content-editor .ProseMirror p {
    margin-bottom: 0px;
}
.pointer {
    cursor: pointer !important;
}

.custom-content-height .ProseMirror {
    height: var(--customHeight);
    overflow-y: scroll;
}
</style>
