<template>
  <div class="gf-ae col pt-2">
    <form :key="renderKey">
      <div class="h5 row justify-content-center text-center pb-2">
        {{ GfLocalisation.getLocale('activity_entry_title') }}
      </div>

      <div
        v-if="errors.length"
        class="alert alert-danger"
      >
        <h5><strong>{{ GfLocalisation.getLocale('errors_title') }}</strong></h5>
        <ul
          style="list-style-type: none;"
          class="mb-1"
        >
          <li
            v-for="error in errors"
            :key="error"
          >
            {{ error }}
          </li>
        </ul>
      </div>

      <div class="form-group row d-flex justify-content-between">
        <label class="col-form-label col-4">
          {{ GfLocalisation.getLocale('exercise_type') }}
          <sup class="text-danger">*</sup>
        </label>
        <div class="col-8 text-right">
          <select
            v-model="activityType"
            class="form-control"
            @change="onActivityTypeChanged"
          >
            <option
              v-for="exerciseType in supportedActivityTypes"
              :key="exerciseType"
              :value="exerciseType"
            >
              {{ exerciseTypes.find(et => et.value === exerciseType).text }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-for="(measurementInputData, measurementInputDataKey) in measurementInputsMetaData"
        :key="measurementInputDataKey"
      >
        <div
          v-if="isFieldVisible(measurementInputData)"
          class="form-group row d-flex justify-content-between mb-3"
        >
          <label class="col-form-label col-4">
            {{ measurementInputData.DisplayName }}
            <template v-if="isRequiredField(measurementInputData)">
              <sup class="text-danger">*</sup>
            </template>
          </label>
          <div class="col-8">
            <MeasurementInput
              :measure="measurementInputData.Measure"
              :aggregate-type="measurementInputData.AggregateType"
              :display="measurementInputData.PlaceholderText"
              :base-unit="measurementInputData.BaseUnit"
              :primary="measurementInputData.Primary"
              :secondary="measurementInputData.Secondary"
              :mode="measurementInputData.ModeForRegion[regionCode]"
              @valueUpdated="onValueUpdated"
            />
          </div>
        </div>
      </div>

      <div class="form group row d-flex justify-content-between mb-3">
        <label class="col-form-label col-4">
          {{ GfLocalisation.getLocale('activity_date') }}
          <sup class="text-danger">*</sup>
        </label>
        <div class="col-8">
          <DatePicker
            v-model="activityDate"
            placeholder-text="Pick a date"
            :region-code="regionCode"
          />
        </div>
      </div>

      <div class="form group row d-flex justify-content-between">
        <label class="col-form-label col-4">
          {{ GfLocalisation.getLocale('start_time') }}
          <sup class="text-danger">*</sup>
        </label>
        <div class="col-8">
          <TimePicker
            v-model="startTime"
            placeholder-text="HH:MM"
          />
        </div>
      </div>

      <div class="row justify-content-end mt-3">
        <div class="col-4">
          <a
            class="col btn gf-driver-button gf-driver-button-rounded border-0 log-activity-btn"
            :class="{ disabled: !state.isValid }"
            role="button"
            @click="logActivity"
          >
            <i class="fa fa-check fa-fw" />
            {{ GfLocalisation.getLocale('save') }}
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Store from "../../common/store";
import logger from "../../common/logger";
import DatePicker from "../Common/DatePicker.vue";
import TimePicker from "../Common/TimePicker.vue";
import ExerciseTypes from "./ExerciseTypes/ExerciseTypes";
import activityManagementStore from "./activityManageStore";
import activityManagementService from "../../common/ActivityManagementService";
import activityManageStore from "./activityManageStore";
import MeasurementInput from "./MeasurementTypes/MeasurementInput.vue";
import measurementInputsMetaData from "./MeasurementTypes/MeasurementInputMetaData";
import * as GfLocalisation from '@gf/gf-localisation';
import moment from 'moment';
import FormatDateHelperFunctions from '../Common/FormatDateHelperFunctions.js';

const defaultSupportedActivities = ExerciseTypes.map(et => et.value)
const defaultActivityType = ExerciseTypes.find(et => et.value === 'Running').value

export default {
	name: "ActivityEntry",

	components: {
    DatePicker,
    TimePicker,
    MeasurementInput,
	},

	props: {
		regionCode: {
			type: String,
			required: true,
			default: "AU",
		},

		userId: {
			type: Number,
			required: true,
			default: 0,
		},

		fundraisingPageId: {
			type: Number,
			required: true,
			default: 0,
		},

		eventCampaignId: {
			type: Number,
			required: true,
			default: 0,
		},
		componentSettings: {
			type: Object,
			default: () => ({
        supportedActivities: defaultSupportedActivities,
        defaultActivityType: defaultActivityType,
        displayAllMeasurements: true,
			}),
		},
	},

	data() {
		return {
			state: activityManagementStore.state,
			activitySettings: {},
			supportedActivityTypes: [],
			renderKey: 0,
			defaultActivityTypeOnEntry: this.componentSettings.defaultActivityType,
      errors: [],
      activityData: [],
      exerciseTypes: ExerciseTypes,
      measurementInputsMetaData: measurementInputsMetaData.SupportedMeasurements,
      activityType: '',
      activityDate: null,
      startTime: '',
      GfLocalisation,
      temp: {
        activityDate: '',
        startTime: '',
      },
      store: Store
		};
	},

	computed: {
		minDate() {
			var date = new Date();
			date.setDate(date.getDate() - 30);
			return `${date.getFullYear()}-${
				date.getMonth() + 1
			}-${date.getDate()}`;
		},
	},

	watch: {
		async eventCampaignId(val) {
			this.activitySettings = await activityManagementService.getActivitySettings(
				this.fundraisingPageId,
				val
			);
		},

		async fundraisingPageId(val) {
			this.activitySettings = await activityManagementService.getActivitySettings(
				val,
				this.eventCampaignId
			);
		},

    'componentSettings.supportedActivities': {
      handler(val) {
        this.supportedActivityTypes = val
      },
      deep: true,
      immediate: true,
    },

		activitySettings(val) {
			if (
				val &&
				val.ConfiguredSettings &&
				val.ConfiguredSettings.ActivityTypes
			) {
        this.supportedActivityTypes = val.ConfiguredSettings.ActivityTypes.map(at => at.Name);
        this.exerciseTypes = val.AvailableSettings.ActivityTypes.map(at => ({
          value: at.Name,
          text: GfLocalisation.getLocale(at.Name)
        }))
      }
		},

    startTime() {
      logger.logInfo(`setting from startTime`)
      const valueWithOffset = this.setDateTimeWithOffset()
      activityManagementStore.setStartTime(valueWithOffset)
    },

    activityDate() {
      logger.logInfo(`setting from activityDate`)
      const valueWithOffset = this.setDateTimeWithOffset()
      activityManagementStore.setStartTime(valueWithOffset)
    },

	},
	async mounted() {
		activityManagementStore.initialiseActivityForUser(this.userId);

    if (typeof this.componentSettings.defaultActivityType === 'undefined') {
      this.componentSettings.defaultActivityType = defaultActivityType
    }

    if (typeof this.componentSettings.supportedActivities === 'undefined') {
      this.componentSettings.supportedActivities = defaultSupportedActivities
    }

    if (typeof this.componentSettings.displayAllMeasurements === 'undefined') {
      this.componentSettings.displayAllMeasurements = true
    }

		if (this.componentSettings.defaultActivityType)
			this.onActivityTypeChanged(
				this.componentSettings.defaultActivityType
			);

		this.activitySettings = await activityManagementService.getActivitySettings(
			this.fundraisingPageId,
			this.eventCampaignId
		);

    this.initializeDateTime()
	},

	methods: {
    async validate() {
      this.errors = []
      const response = await (new Promise((resolve, reject) => {
        let errors = []
        const stepMetaData = this.measurementInputsMetaData.find(input => input.Measure === 'Step')
        this.temp.activityDate = ''
        this.temp.startTime = ''
        for (let i = 0; i < activityManageStore.state.payload.length; i += 1) {
          if (activityManageStore.state.payload[i].ActivityType.Name === null) {
            errors.push(GfLocalisation.getLocale('activity_entry.validation.exercise_type_required'))
          }

          if (activityManageStore.state.payload[i].ActivityMeasurements.some(a => a.Measure === 'Step') === false && stepMetaData.RequiredExerciseTypes.some(ret => ret === this.activityType)) {
            errors.push(GfLocalisation.getLocale('activity_entry.validation.steps_required'))
          }

          if (activityManageStore.state.payload[i].ActivityMeasurements.some(a => a.Measure === 'Time') === true
              && activityManageStore.state.payload[i].ActivityMeasurements.find(a => a.Measure == 'Time').Value == "") {
            errors.push(GfLocalisation.getLocale('activity_entry.validation.duration_required'))
          }

          if (this.startTime === null || !this.startTime) {
            errors.push(GfLocalisation.getLocale('activity_entry.validation.start_time_required'))
          }

          if (this.activityDate === null || !this.activityDate) {
            errors.push(GfLocalisation.getLocale('activity_entry.validation.activity_date_required'))
          }

          var offSetDate = this.setDateTimeWithOffset()

          var validDate = moment(offSetDate);

          if(!validDate.isValid() || validDate <= '2000/01/01')
            errors.push(GfLocalisation.getLocale('activity_entry.validation.activity_date_required'))

          this.activityData = activityManageStore.state.payload[i]
        }

        if (errors.length) {
          this.temp.activityDate = this.activityDate
          this.temp.startTime = this.startTime
          reject(errors)
        }

        resolve()
      }))
      return response
    },

		async logActivity() {
      try {
        activityManagementStore.addActivity();

        await this.validate()

        var result = await activityManagementService.addActivities(
          activityManageStore.state.payload
        );
        logger.logInfo("logActivity - got result", result);
        if (result && result.type == "success") {
          activityManagementStore.addManualActivities(
            activityManageStore.state.payload
          );
          this.activityAddSuccess();
          activityManagementStore.initialiseActivityForUser(this.userId)
        }
        this.initializeDateTime()
      } catch (e) {
        this.errors = e
        activityManagementStore.initialiseActivityForUser(this.userId, this.activityData)
        this.activityDate = this.temp.activityDate
        this.startTime = this.temp.startTime
      }
		},

		activityAddSuccess() {
			this.renderKey += 1;
			activityManagementStore.initialiseActivityForUser(this.userId);
			this.$toasted.show(
        GfLocalisation.getLocale('activity_entry_success_message'),
				{
					duration: 3000,
					position: "bottom-right",
					className: [
						"gf-driver-background-bg",
						"gf-driver-colour-fg",
					],
				}
			);
			this.closeComponent();
		},

    onValueUpdated(val) {
      logger.logInfo('onValueUpdated', val)
      activityManagementStore.upsertMeasurement(
        val.Measure,
        val.MeasurementUnit,
        val.AggregateType,
        val.Value
      );
    },

		onDetailsChanged(val) {
			activityManagementStore.setActivityDescription(val.description);
			activityManagementStore.setActivityName(val.title);
		},

		onActivityTypeChanged(val) {
      const v = typeof val.target != 'undefined' && typeof val.target.value != 'undefined' ? val.target.value : val
			activityManagementStore.setActivityType(v);
      this.activityType = v
		},

    onExerciseTypeChanged(val) {
      activityManagementStore.setExerciseType(val.target.value);
    },

		closeComponent() {
			this.$root.$emit("closeComponent");
		},

    isFieldVisible(data) {
      return typeof data.ExerciseTypes === 'undefined' ||
        (
          typeof data.ExerciseTypes !== 'undefined' &&
          (
            data.ExerciseTypes.some(et => {
            return typeof activityManagementStore.state.currentActivity.ActivityType !== 'undefined' &&
              typeof activityManagementStore.state.currentActivity.ActivityType.Name != 'undefined' &&
              et === activityManagementStore.state.currentActivity.ActivityType.Name
            }) || data.ExerciseTypes.length === 0
          )
        )
    },

    isRequiredField(data) {
      return data.RequiredExerciseTypes.some(ret => ret === this.activityType) ||
        (data.RequiredExerciseTypes.length === 0 && data.Required === true)
    },

    initializeDateTime() {
      this.$nextTick(() => {
        this.startTime = this.setTime()
        this.activityDate = this.setDate()
      })
    },

    setDate(val = new Date()) {
      var dtFormated = FormatDateHelperFunctions.setDateFormatByRegion(this.regionCode);
      return moment(val).format(dtFormated.momentDtFormat)
    },

    setTime(val = new Date(), format = 'HH:mm') {
      const time = moment(val).format(format)
      logger.logInfo('setting current time to', time)
      return time
    },

    setDateTimeWithOffset() {
      var dateFormat = 'YYYY/MM/DD'
      var activityDate = moment().format(dateFormat)
      var startTime = moment()
      var currentDtFormat;

      var dtFormated = FormatDateHelperFunctions.setDateFormatByRegion(this.regionCode);
      currentDtFormat = dtFormated.momentDtFormat;

      var valueWithOffset = moment()
       if (this.activityDate && this.startTime) {
        activityDate =  moment(this.activityDate, currentDtFormat)
        startTime = moment(this.startTime,["h:mm A"])
        valueWithOffset = activityDate.set({
          hour: startTime.get('hour'),
          minute: startTime.get('minute')
        })
        valueWithOffset = moment(valueWithOffset).utcOffset(moment().utcOffset(), true)
      }

      return valueWithOffset.format()
    }

	},
};
</script>

<style lang="scss" scoped>
.log-activity-btn {
  min-width: auto !important;
}
</style>
