<template>
  <div>
    <div class="col">
      <div class="row pt-2">
        <div>
          <profile-image
            class="float-left"
            :display-name="creator.name"
            :image-src="creator.displayImage"
            :gender="creator.gender"
            :size="profileImageSize"
            :rounded="profileImageRounded"
          />
        </div>
        <div class="col">
          <div class="row">
            <div
              v-if="showTitle"
              class="col-8 col-md-8 h5 font-weight-bold"
            >
              {{ title }}
            </div>
            <div
              class="col-4 col-md-4 h5 font-weight-bold text-right"
            >
              <span
                v-if="showAmountText"
                class="gf-pde-amount-text"
              >{{ amountText }}</span>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              :class="displayTextClassAlignClass"
              v-html="formattedDisplayText"
            />
          </div>
          <div
            v-if="message"
            class="py-2 row"
          >
            <div class="h5 font-italic col-12">
              <div
                class="col gf-pde-message"
                v-html="message"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="d-none d-md-block col-md-9" />
        <div class="col-12 col-md-2">
          <div
            v-if="showReplyButton"
            class="float-right gf-driver-button-hollow btn btn-sm gf-driver-button-rounded gf-btn-reply"
            @click="$emit('reply-clicked')"
          >
            {{ replyButtonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileImage from "../Common/ProfileImage"
import moment from "moment";

export default {
    name: "ContentItem",
    components: {
      ProfileImage
    },
    
    props: {
        profileImageSize: {
          type: Number,
          default: 50
        },
        profileImageRounded: {
          type: Boolean,
          default: true
        },
        showReplyButton: {
          type: Boolean,
          default: true
        },
        replyButtonText: {
          type: String,
          default: "Reply"
        },
        showTitle: {
          type: Boolean,
          default: true
        },
        title: {
          type: String,
          default: ""
        },
        displayText: {
            type: String,
            default: ""
        },
        showAmountText: {
            type: Boolean,
            default: true
        },
        amountText: {
          type: String,
          default: null
        },
        message: {
            type: String,
            default: null
        },
        textAlign: {
          type: String,
          default: 'left'
        },

        creator: {
            type: Object,
            default: () => {
                return {
                    displayImage: "",
                    name: "",
                    gender: "M"
                };
            }
        },
        createdDate: {
            type: String,
            default: ''
        }
    },

    computed: {
      formattedDisplayText() {
        var msg = this.displayText;
        if (this.createdDate)
          msg = `${msg} on <b>${this.formattedDate(this.createdDate)}</b>`;
        return msg;
      },
      displayTextClassAlignClass() {
        if (this.textAlign.toLowerCase() === "justify")
          return "text-justify";
        if (this.textAlign.toLowerCase() === "right")
          return "text-right";

        return "text-left";
      }
    },

    methods: {
      formattedDate(val) {
        var date = isNaN(val) ? val : parseInt(val);
        return moment(date).isValid()
            ? moment(date).format("MMMM D YYYY")
            : "";
      }
    }
};
</script>
<style scoped>
.gf-pde-message {
    border-left: solid 5px #ccc;
}

.gf-pde-amount-text {
  color: #888;
  letter-spacing: 0.5px;
}
</style>
